<template>
  <div class="filterbar--base" :class="{ 'is-mdAndUp': $vuetify.breakpoint.mdAndUp }">
    <v-text-field
      type="text"
      :label="$t('label.searchInput')"
      :error="isSearchQueryValid"
      :error-messages="searchQueryMessage"
      v-model="searchQuery"
      clearable
      prepend-icon="mdi-magnify"
      class="filterbar__item"
      @input="onChangeFilter"
    >
    </v-text-field>

    <div class="filterbar__item mt-7">
      <h3 class="h2">{{ $t('Settings') }}</h3>
      <v-divider></v-divider>
    </div>

    <v-combobox
      v-model="selectedTableColumns"
      :items="tableColumns"
      :item-text="item => $t(item.text)"
      :label="$t('label.selectedTableColumns')"
      :disabled="!tableColumns.length"
      multiple
      clearable
      class="filterbar__item "
      @change="onChangeFilter"
    >
      <template v-slot:selection="{ item }">
        <v-chip small color="accent">
          <span>{{ $t(item.text) }}</span>
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
export default {
  name: 'FilterBarBase',

  props: {
    filterOptions: {
      type: Object,
      required: false,
      default() {
        return {
          tags: []
        };
      }
    },

    protectedSelectedTagValues: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },

    selectedColumns: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },

    selectedFilterOptions: {
      type: Object,
      required: false,
      default() {
        return {
          tags: []
        };
      }
    },

    tableColumns: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },

  computed: {
    allFilters() {
      return {
        columns: this.selectedTableColumns,
        query: this.searchQuery,
        tags: this.selectedTags
      };
    },

    isSearchQueryValid() {
      let result = false;
      if (this.searchQuery && this.searchQuery.length && this.searchQuery.length <= this.minSearchQueryLength) {
        result = true;
      }
      return result;
    },

    searchQueryMessage() {
      let result = '';
      if (this.searchQuery && this.searchQuery.length && this.searchQuery.length <= this.minSearchQueryLength) {
        result = this.$t('error.searchQueryTooShort');
      }
      return result;
    }
  },

  data() {
    return {
      minSearchQueryLength: 0,
      searchQuery: '',
      selectedTableColumns: [],
      selectedTags: []
    };
  },

  mounted() {
    this.preSelectColumns();
    // this.onChangeFilter();
  },

  methods: {
    preSelectColumns() {
      if (this.selectedColumns.length) {
        this.selectedTableColumns = this.selectedColumns.slice();
      }
    },

    onChangeFilter() {
      this.$emit('change', this.allFilters);
    }
  },

  watch: {
    selectedColumns() {
      this.preSelectColumns();
    }
  }
};
</script>

<style lang="scss" scoped>
.filterbar--base {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.filterbar__item {
  @media screen and (min-width: $screen-xs) {
    margin: 0 0.625rem;
  }

  .is-inside-nav-drawer & {
    &:not(:first-child) {
      margin-top: 0.625rem;
    }
  }

  .is-inside-nav-drawer.is-mdAndUp & {
    width: 100%;
  }

  @media screen and (min-width: $screen-md) {
    width: calc((100% / 3) - 0.625rem * 2);
  }
}
</style>
